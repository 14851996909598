<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item to="/song-manage">歌曲管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/album/' + this.song.albumId">专辑详情</el-breadcrumb-item>
      <el-breadcrumb-item>歌曲详情</el-breadcrumb-item>
    </el-breadcrumb>

    <el-divider></el-divider>

    <div style="background-color: #F8F8F8;display: flex;justify-content: space-between">
      <div style="padding: 25px 30px">
        <div style="display: flex">
          <div class="s3-18-500" style="margin-right: 14px">歌曲详情</div>
          <hi-examine-status-label :status="song.status" :examine-desp="song.examineDesp"></hi-examine-status-label>
        </div>

        <div style="display: flex;margin-top: 24px">
          <div style="display: flex;flex-direction: column;justify-content: space-between;height: 52px">
            <div style="display: flex;align-items: center">
              <div style="max-width: 320px;font-size: 16px;color: #333333;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;" :title="song.name">{{ song.name }}</div>
              <hi-mini-player v-if="song.songPath" :src="song.songPath"></hi-mini-player>
            </div>
            <div style="display: flex" v-if="song.issueStatus === 1">
              <div class="info-item" style="width: 190px">
                <span class="label">首发时间 </span>{{ song.issueTime || ''}}
              </div>
              <div class="info-item">
                <span class="label">发行天数 </span>{{ song.issueDay || ''}}
              </div>
            </div>
          </div>
        </div>

        <div style="margin-top: 14px;display: flex;align-items: center">
          <hi-album-cover v-if="song.albumCover" :src="song.albumCover" :edge="30"></hi-album-cover>
          <div style="font-size: 12px;color: #666666;margin-left: 10px">
            {{ song.singer }}-专辑《{{ song.albumName }}》
          </div>
        </div>

        <div class="s3-18-500" style="margin-top: 40px">歌曲信息</div>

        <div style="display: flex;margin-top: 24px">
          <div class="info-item" style="width: 180px">
            <span class="label">演唱 </span>
            <span :title="song.singer">{{ song.singer || '-'}}</span>
          </div>
          <div class="info-item" style="width: 180px">
            <span class="label">作词 </span>
            <span :title="song.lyricist">{{ song.lyricist || '-'}}</span>
          </div>
          <div class="info-item" style="width: 180px">
            <span class="label">作曲 </span>
            <span :title="song.composer">{{ song.composer || '-'}}</span>
          </div>
        </div>

        <div style="display: flex;margin-top: 20px">
          <div class="info-item" style="width: 180px">
            <span class="label">语种 </span>
            <span :title="song.singer">{{ song.language || '-'}}</span>
          </div>
          <div class="info-item" style="width: 180px">
            <span class="label">流派 </span>
            <span :title="song.schools">{{ song.schools || '-'}}</span>
          </div>
        </div>

      </div>
      <div style="width: 388px;border-left: #EFEFEF 1px solid">
        <div style="border-bottom: #EFEFEF 1px solid;line-height: 56px;font-size: 14px;padding-left: 11px;color: #333333">歌词</div>
        <div style="height: 306px;overflow-y: scroll;position: relative">
          <pre style="text-align: center;color: #666666;font-size: 14px">{{ song.lrc }}</pre>
        </div>
      </div>
    </div>

    <div v-if="song.status === 1">
      <div style="display: flex;justify-content: space-between;margin: 40px 0 27px">
        <div class="s3-18-500">发行记录</div>
        <hi-simple-pagination v-if="channelsPic" :total="channelsPic.length" :page.sync="pageNum" :page-size="3"></hi-simple-pagination>
      </div>

      <el-table
          v-if="channelsPic !== null"
          :data="channelsPic.slice(pageNum * 3 - 3, Math.min(pageNum * 3, channelsPic.length))"
          style="width: 100%">
        <el-table-column
            v-slot="{row}"
            label="发行平台">
          <el-image :src="row" style="height: 20px;width: 20px"></el-image>
        </el-table-column>
        <el-table-column
            label="发行时间">
          {{ song.issueTime }}
        </el-table-column>
        <el-table-column
            label="上架状态"
            width="100">
          已上架
        </el-table-column>
      </el-table>
      <div v-else style="color: #999999;font-size: 14px">暂无发行记录</div>

      <div v-show="songRightVos && songRightVos.length > 0" class="s3-18-500" style="margin: 40px 0 27px">授权信息</div>

      <div style="display: flex;border: #F1F1F1 1px solid;padding: 8px 0 20px 24px;margin-bottom: 20px"
           v-for="(r, index) in songRightVos" :key="index">
        <div style="width: 50%">
          <div class="info-item" style="margin-top: 12px">
            <span class="label">授权年限 </span>{{ r.limit || '-'}}
          </div>
          <div class="info-item" style="margin-top: 12px">
            <span class="label">授权日期 </span>{{ r.dateStarting || '-' }} - {{ r.dateEnding || '-' }}
          </div>
          <div class="info-item" style="margin-top: 12px">
            <span class="label">授权形式 </span>{{ r.exclusive || '-' }}
          </div>
          <div class="info-item" style="margin-top: 12px">
            <span class="label">授权区域 </span>{{ r.district || '-' }}
          </div>
        </div>
        <div style="width: 50%">
          <div class="info-item" style="margin-top: 12px">
            授权权益
          </div>
          <div class="info-item" style="margin-top: 12px" v-for="(right, i) in r.rights" :key="i">
            <span class="label">{{ right.role.join(',') }} </span>
            <el-link
                type="primary"
                target="_blank"
                style="vertical-align: baseline;text-decoration-line: underline"
                :underline="false"
                :href="r.files[i]">
              {{ right.right || '-' }}
            </el-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {songDetail} from "@/api/song"
import HiExamineStatusLabel from "@/views/songManage/components/HiExamineStatusLabel"
import HiAlbumCover from "@/views/songManage/components/HiAlbumCover"
import HiSimplePagination from "@/views/songManage/components/HiSimplePagination"
import HiMiniPlayer from "@/components/HiMiniPlayer"

export default {
  name: "Song",
  props: {
    id: {
      type: String
    }
  },
  components: {
    HiExamineStatusLabel,
    HiAlbumCover,
    HiSimplePagination,
    HiMiniPlayer
  },
  data(){
    return {
      channelsPic: null,
      song: {},
      songRightVos: [],

      pageNum: 1
    }
  },
  mounted() {
    this.getSongDetail()
  },
  methods: {
    getSongDetail(){
      songDetail({}, {songId: this.id}).then(r => {
        this.song = r.data.musicianSong
        this.channelsPic = r.data.channelsPic !== null ? r.data.channelsPic.split(',') : null
        this.songRightVos = r.data.songRightVos
      })
    }
  }
}
</script>

<style scoped lang="scss">
.s3-18-500{
  color: #333333;
  font-size: 18px;
  font-weight: 500;
  font-family: "PingFang SC-Medium";
}
.info-item{
  font-size: 14px;
  color: #333333;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  .label{
    color: #999999;
  }

}
</style>
