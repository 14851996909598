<template>
  <div style="display: flex;margin-left: 12px">
    <audio controls id="player" :src="src" style="width: 0;height: 0;">
      Your browser does not support this audio format.
    </audio>
    <el-image v-if="!playing" @click="play" :src="PlayIcon" style="width: 16px;height: 16px"></el-image>
    <el-image v-else @click="pause()" :src="PauseIcon" style="width: 16px;height: 16px"></el-image>
    <span style="font-size: 10px;color: #999999;margin-left: 4px"> {{ time }}</span>
  </div>
</template>

<script>
import PlayIcon from '@/assets/play.png'
import PauseIcon from '@/assets/suspend.png'
export default {
  name: "HiMiniPlayer",
  props: {
    src: String
  },
  data(){
    return {
      PauseIcon,
      PlayIcon,

      playing: false,
      player: null,
      time: '00:00'
    }
  },
  mounted() {
    this.player = document.getElementById('player')

  },
  methods: {
    play(){
      this.player.play().then(() => {
        this.playing = true
        const duration = this.player.duration
        let min = (duration / 60).toFixed(0)
        if (min < 10) min = '0' + min
        let second = (duration % 60).toFixed(0)
        if (second < 10) second = '0' + second
        this.time = `${min}:${second}`
      })
    },
    pause(){
      this.player.pause()
      this.playing = false
    }
  },
  watch: {
    'player.readyState' () {
      console.log('player.readyState')
    }
  }
}
</script>

<style scoped>

</style>
