<template>
  <div style="display: flex;align-items: center">
    <div v-if="status === 0" class="label s0">审核中</div>
    <div v-if="status === 1" class="label s1">已授权</div>
    <div v-if="status === 2" class="label s2">审核失败</div>
    <el-tooltip v-if="status === 2" effect="dark" :content="examineDesp" placement="bottom" style="margin-left: 8px">
      <el-image :src="failInfoImage" style="width: 14px;height: 14px"></el-image>
    </el-tooltip>
    <div v-if="status === 4" class="label s0">草稿</div>
  </div>
</template>

<script>
import failInfoImage from '@/assets/fail-info.png'
export default {
  name: "HiExamineStatusLabel",
  props: {
    status: Number,
    examineDesp: String
  },
  data(){
    return {failInfoImage}
  }
}
</script>

<style scoped>
.label{
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  border-radius: 4px;
  padding: 0 14px;
}
.s0{
  color: #A5773F;
  background-color: #FFEDBC;
}
.s1{
  color: #6A9B5D;
  background-color: #E2FFE0;
}
.s2{
  color: #E33641;
  background-color: #FFD8D8;
}
</style>
